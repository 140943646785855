<template>
<div classs="bg-white">
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" style="background-image: url('/img/Abstract3drender-small.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading KPMG Controls</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
        <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{count}} controls</div>
      </div>
    </transition>
  </div>
  <b-container fluid v-if="!loading" class="bg-white" style="margin: 0px; padding: 0px;">
      <b-row style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'Controls' }" exact exact-active-class="active">Home</b-nav-item>
                <b-nav-item :to="{ name: 'ControlsControls' }" exact exact-active-class="active">Controls</b-nav-item>
                <b-nav-item :to="{ name: 'ControlsMatrix' }" exact exact-active-class="active">Matrix</b-nav-item>
                <b-nav-item :to="{ name: 'ControlsNews', params: { id: 154 } }" exact exact-active-class="active">News</b-nav-item>
                <b-nav-item :to="{ name: 'ControlsInsights' }" exact exact-active-class="active">Insights</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'ControlsDataStrats' }" exact exact-active-class="active">Data stratification</b-nav-item>
                <b-nav-item v-if="permissions.add" :to="{ name: 'FormNew', params: { model: 'control' }}" exact exact-active-class="active">New control</b-nav-item>
              </b-nav>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item v-if="loadingBackground">Reloading {{ count }} records</b-nav-item>
                <b-nav-item @click="reload" v-b-popover.hover.top="'Reload data'">Reload <b-icon-arrow-clockwise v-if="!loadingBackground"></b-icon-arrow-clockwise><b-icon-arrow-clockwise animation="spin" v-if="loadingBackground"></b-icon-arrow-clockwise></b-nav-item>
                <b-nav-item >{{ controls.length}} controls loaded</b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row v-if="ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import PouchDB from 'pouchdb'

import { BIconArrowClockwise } from 'bootstrap-vue'

const getParentsFlatArrayIds = function (item) {
  if (item.parents.length === 0) {
    return [item.id]
  } else {
    const result = item.parents.map(x => {
      return [getParentsFlatArrayIds(x), item.id]
    })
    return result
  }
}

let db = new PouchDB('controls')

export default {
  name: 'ControlsLayout',
  components: {
    BIconArrowClockwise
  },
  computed: {
    controls: {
      get () {
        return this.$store.state.controls
      },
      set (payload) {
        this.$store.commit('setControls', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "KPMG Controls"
    this.$store.commit('setBrand', 'Controls')
    this.$stat.log({ page: 'controls', action: 'open controls' })
    if (this.$config.BUILD === 'external') {
      this.permissions.add = false
    } else {
      this.permissions.add = ac.can(this.user.acgroups).createAny('control').granted
    }
    await this.load()
  },
  data () {
    const data = {
      count: 0,
      loading: true,
      loadingBackground: false,
      permissions: {
        add: false
      },
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      let loadFromDb = false
      let reloadFromDb = false
      const infoDb = await db.info()
      if (this.controls.length === 0) {
        if (infoDb.doc_count === 0) {
          loadFromDb = true
        } else {
          let controls = await db.allDocs({ include_docs: true })
          controls = controls.rows.map(x => x.doc)
          this.count = controls.length
          this.controls = controls
          reloadFromDb = true
        }
      }
      if (loadFromDb) {
        await Promise.all([this.loadRows()])
        this.loading = false
        this.ready = true
      } else if (reloadFromDb) {
        this.loading = false
        this.ready = true
        this.loadingBackground = true
        await Promise.all([this.loadRows()])
        this.ready = false
        this.loadingBackground = false
        this.ready = true
      } else {
        this.loading = false
        this.ready = true
      }
    },
    loadRows: async function () {
      let rows = []
      try {
        const limit = 50
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/controls/limit/${limit}/offset/${offset}`)
          const data = rows.concat(response)
          rows = data
          this.count = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        rows = _.uniqBy(rows, 'id')
        rows.forEach(row => {
          row.taxonomyIds = []
          if (row.taxonomy.length > 0) {
            row.taxonomy.forEach(t => {
              row.taxonomyIds.push(getParentsFlatArrayIds(t))
            })
            row.taxonomyIds = _.uniq(_.flattenDeep(row.taxonomyIds))
          }
        })
        this.controls = rows
        const info = await db.info()
        if (info.doc_count > 0) {
          await db.destroy()
          db = null
          db = new PouchDB('controls')
        }
        await db.bulkDocs(this.controls)
      } catch (e) {
        console.log(e)
      }
    },
    reload: async function () {
      this.loadingBackground = true
      try {
        await Promise.all([this.loadRows()])
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
      this.ready = false
      this.loadingBackground = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar a:hover {
  font-weight: bold;
}
#top-menu-bar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-bar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
